<template>
  <div class="content">
    <el-form inline
             :model="search"
             class="tb-form">
      <!-- <el-form-item label="品牌名称：">
                <el-select v-model="search.brandid" clearable>
                    <el-option :label="item.brandName" :value="item.id" v-for="item in brandOptions"
                               :key="item.id"></el-option>
                </el-select>
            </el-form-item> -->
      <el-form-item label="商品名称：">
        <el-input v-model="search.productname"></el-input>
      </el-form-item>
      <el-form-item label="买家手机号：">
        <el-input v-model="search.phone"></el-input>
      </el-form-item>
      <el-form-item label="买家昵称：">
        <el-input v-model="search.nickname"></el-input>
      </el-form-item>
      <el-form-item label="订单编号：">
        <el-input v-model="search.ordersn"></el-input>
      </el-form-item>
      <el-form-item label="下单时间：">
        <el-date-picker v-model="search.date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        :clearable="false"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="onSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="add">
      <el-button-group>
        <el-button v-for="item in statusOptions"
                   :key="item.status"
                   :type="search.status === item.status ? 'primary' : ''"
                   @click="changeStatus(item.status)">
          {{item.name}}
        </el-button>
      </el-button-group>
    </div>
    <div class="tablebox">
      <el-table :data="tableData"
                class="tb-table"
                @row-dblclick="toDetail">
        <el-table-column label="订单号"
                         min-width="150">
          <template slot-scope="scope">
            <el-popover width="200"
                        placement="top"
                        trigger="hover"
                        :content="scope.row.ordersn">
              <div slot="reference"
                   class="ellipsis-one">{{scope.row.ordersn}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="date"
                         label="下单时间"
                         min-width="100" />
        <el-table-column prop="refunded_at"
                         label="申请退款时间"
                         min-width="120" />
        <!-- <el-table-column label="关联品牌" min-width="130">
                    <template slot-scope="scope">
                        <el-popover
                                width="100"
                                placement="top"
                                trigger="hover"
                                :content="scope.row.brand_name"
                        >
                            <div slot="reference" class="ellipsis-one">{{scope.row.brand_name}}</div>
                        </el-popover>
                    </template>
                </el-table-column> -->
        <el-table-column prop="nums"
                         label="商品">
          <span slot-scope="scope">{{scope.row.nums}}件商品</span>
        </el-table-column>
        <el-table-column prop="price"
                         label="订单总价" />
        <el-table-column prop="contactor"
                         label="收货人" />
        <el-table-column label="收货地址"
                         min-width="250px">
          <template slot-scope="scope">
            <el-popover width="300"
                        placement="top"
                        trigger="hover"
                        :content="scope.row.address">
              <div slot="reference"
                   class="ellipsis-two">{{scope.row.address}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="mobile"
                         label="手机号"
                         min-width="120" />
        <el-table-column prop="statusname"
                         label="状态" />
        <el-table-column prop="total"
                         label="商品总量" />
        <el-table-column label="操作">
          <router-link slot-scope="scope"
                       :to="`/refund/detail?id=${scope.row.id}`"
                       class="edit">编辑
          </router-link>
        </el-table-column>
      </el-table>
      <el-pagination background
                     class="tb-pagination"
                     layout="prev, pager, next"
                     :current-page.sync="page.no + 1"
                     :page-size="page.size"
                     :total="page.total"
                     @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RefundList',
  data () {
    return {
      search: {
        brandid: 71,
        branchname: '',
        productname: '',
        phone: '',
        nickname: '',
        ordersn: '',
        status: -1,
        date: [this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
      },
      statusOptions: [
        { status: -1, name: '所有订单' },
        { status: 0, name: '待处理' },
        { status: 1, name: '已通过' },
        { status: 2, name: '已拒绝' }
      ],
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0
      }
    }
  },
  computed: mapState([
    'brandOptions'
  ]),
  mounted () {
    let path = this.$route.path
    if (path.indexOf('/') === 0) path = path.substring(1)
    const currentPage = {
      link: path,
      name: '退款处理'
    }
    this.$store.commit('changeRoute', currentPage)
    this.getList()
  },
  methods: {
    async getList () {
      let { no, size } = this.page
      let { brandid, status, phone, nickname, date, ordersn } = this.search
      const params = {
        page_size: size,
        page_no: no,
        start_time: date[0] + ' 00:00:00',
        end_time: date[1] + ' 23:59:59',
        brand_id: brandid,
        contactor: nickname,
        status,
        mobile: phone,
        ordersn
      }
      const url = 'admin/order/refund/list'
      const data = await this.$https.get(url, { params })
      if (!data) return
      const list = data.list.filter(item => item.status !== -1)
      list.forEach(item => {
        item.date = this.$dayjs(item.created_at).format('YYYY-MM-DD hh:mm')
        item.refunded_at = (item.refunded_at) ? this.$dayjs(item.refunded_at).format("'YYYY-MM-DD hh:mm'") : ""
        item.price = this.$np.divide(item.amount, 100)
        item.nums = item.items.length
        item.statusname = ['待审核', '已通过', '已拒绝'][item.status]
        item.total = 0
        item.items.forEach(product => {
          item.total += product.num
        })
      })
      this.page.total = data.total_count
      this.tableData = list
    },
    onSearch () {
      this.page.no = 0
      this.getList()
    },
    changeStatus (type) {
      this.search.status = type
      this.getList()
    },
    pageChange (no) {
      this.page.no = no - 1
      this.getList()
    },
    toDetail (row) {
      this.$router.push(`/refund/detail?id=${row.id}`)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
